const state = {
  isMobile: null,
  isMobileNavOpen: false,
  scrolledElement: null,
  lastScrolledElement: null
};

export default {
  state: state,
  api: {
    /**
     * Sets the element to scroll to (by id or selector).
     *
     */
    set scrolledElement(target) {
      if (typeof target === 'string') {
        state.scrolledElement = document.querySelector(target);
      } else if (target instanceof HTMLElement) {
        state.scrolledElement = target;
      }
    },

    /**
     * Gets the element to scroll to (by id or selector).
     *
     */
    get lastScrolledElement() {
      return state.lastScrolledElement;
    },

    /**
     * Sets the state of the mobile menu (open or not)
     *
     */
    set isMobileNavOpen(newState) {
      return state.isMobileNavOpen = newState;
    },

    /**
     * Check the state of the mobile menu (open or not)
     *
     */
    get isMobileNavOpen() {
      return state.isMobileNavOpen;
    }
  }
}
