export function buildVueElements(arrEl) {
  const arrDir = 'text|html|show|if|else|else-if|for|slot|pre|once|cloak'.split('|');
  const prefixProp = 'prop-';
  const prefixEvent = 'on--';

  arrEl.forEach((ea) => {
    ea[1].map((e) => e.substr(7)).forEach((part) => {
      const val = ea[0].getAttribute(`data-v-${part}`);
      if (part === 'ref') {
        ea[0].setAttribute(`ref`, val);
      } else if (arrDir.includes(part)) {
        ea[0].setAttribute(`v-${part}`, val);
      } else if (part.startsWith(prefixEvent)) {
        ea[0].setAttribute(`v-on:${part.split(prefixEvent)[1]}`, val);
      } else if (part.startsWith(prefixProp)) {
        ea[0].setAttribute(`:${part.split(prefixProp)[1]}`, val);
      } else if (part.startsWith('class')) {
        ea[0].setAttribute(`:${part}`, val);
      }
      ea[0].removeAttribute(`data-v-${part}`);
    });
  });
}

export function getElementsWithAttrsStartWith(prefix, root = document, rootSelector = '*') {
  const r = [];
  [...root.querySelectorAll(rootSelector)]
    .filter((e) => {
      const a = [...e.attributes].filter(({ name }) => name.startsWith(prefix)).map((e) => e.name)
      return a.length > 0 && r.push([e, a]);
    });
  return r;
}
