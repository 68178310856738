import Vue from 'vue';

export default Vue.component('video-component', {
  name: "Video",

  inject: [
    '$store'
  ],

  props: {
    title: {
      type: String,
      default: null
    },
    src: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'hosted'
    },
    state: {
      type: String,
      default: 'stop'
    }
  },

  data() {
    return {
    };
  },

  watch: {
    src(src) {
      try {
        this.$refs.player.src = src;
        this.$refs.player.play();
      } catch (error) {
      }
    },
    state(state) {
      if (state === 'play') {
        this.$refs.player.play();
      }
    }
  },
});
