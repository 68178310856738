// Vendor
import AOS from "aos";
import Vue, { nextTick } from 'vue';
import MatchHeight from '@tannerhodges/match-height';

// Helpers
import * as Dom from '../../helpers/dom';
import { credits, getNS, onMediaQueryChange } from '../../helpers/utils';
import { Lazyload } from '../../helpers/lazyload';

// Stores
import storeMain from './main.store';

// Components
import sliderVue from '../../components/core/slider/slider.vue';
import headerVue from '../../components/custom/header/header.vue';
import videoVue from '../../components/custom/video/video.vue';
import videoGalleryVue from '../../components/custom/video-gallery/video-gallery.vue';

// Converts breakpoint (string) values to numeric values
const breakpoints = getNS().config.breakpoints;
Object.keys(breakpoints).forEach(k => breakpoints[k] = parseFloat(breakpoints[k]));

const mqoIsMobile = matchMedia(
  `(max-width: ${breakpoints.md - 0.1}px)`
);

const fnCheckMobile = (e) => {
  e = e || mqoIsMobile;
  storeMain.state.isMobile = e.matches;
};

onMediaQueryChange(mqoIsMobile, fnCheckMobile);

credits();

AOS.init();

addEventListener('DOMContentLoaded', () => {

  Dom.buildVueElements(Dom.getElementsWithAttrsStartWith('data-v-'));

  zaux.instance = new Vue({
    name: 'Zaux',
    el: '#zaux-content-outer',

    data: storeMain.state,

    computed: {
      $store: () => storeMain.api
    },

    provide: {
      $store: storeMain.api
    },

    created: () => {
      window.addEventListener('load', () => {
        MatchHeight.update();
      });
    },

    mounted: () => {
      fnCheckMobile();

      MatchHeight.reset();
      MatchHeight.update();

      Lazyload.init();
    },

    watch: {

      isMobile(state) {
        document.body.classList[state ? 'add' : 'remove']('is-mobile');
      },

      scrolledElement() {

        if (this.scrolledElement) {
          const bounds = this.scrolledElement.getBoundingClientRect();

          window.scrollTo({
            top: bounds.top + window.scrollY,
            left: 0,
            behavior: "smooth"
          });

          if (this.scrolledElement.id) {
            history.pushState(null, null, `#${this.scrolledElement.id}`);
          }

          // Saves reference of the current (latest) scrolled element (read below)
          this.lastScrolledElement = this.scrolledElement;

          // This let whatcher work in case of multiple scrolls to the same element
          nextTick(() => {
            this.scrolledElement = null;
          });

        }
      }
    }
  });
});

window.addEventListener('scroll', (event) => {
  document.body.style.setProperty('--scroll-top', `${window.scrollY}px`);
});
