export function credits() {
  console.log(`%c   ______     ______     __  __     __
  /\\___  \\   /\\  __ \\   /\\ \\/ /    /\\ \\
  \\/_/  /__  \\ \\  __ \\  \\ \\  _"-.  \\ \\ \\
    /\\_____\\  \\ \\_\\ \\_\\  \\ \\_\\ \\_\\  \\ \\_\\
    \\/_____/   \\/_/\\/_/   \\/_/\\/_/   \\/_/

      Creative Digital Agency —— zaki.it

            Communicate. Better.\n`, "font-family:monospace;color:#cd2d45;");
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getVarNS(capitalized = false, namespaceName = 'zaux') {
  return capitalized ? capitalize(namespaceName) : namespaceName;
}

export function getNS() {
  return window[getVarNS()] = window[getVarNS()] || {};
}

export function onMediaQueryChange(mqo, fn) {
  try {
    // Chrome & Firefox
    return mqo.addEventListener('change', fn) || true;
  } catch (e) {
    try {
      // Safari
      return mqo.addListener(fn) || true;
    } catch (e) {
      console.error(e);
    }
  }
  return false;
}
