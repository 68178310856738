import Vue from 'vue';
import Swiper from 'swiper';
import { EffectFade, Navigation, FreeMode, Pagination } from 'swiper/modules';

// Helpers
import { Lazyload } from '../../../helpers/lazyload';

export default Vue.component('slider-component', {
  name: "Slider",

  inject: [
    '$store'
  ],

  props: {
    config: {
      type: Object,
      default: {}
    },
  },

  data() {
    return {
      _config: null,
      defaultConfig: {},
      instance: null
    };
  },

  mounted() {
    this.init();
  },

  methods: {

    init() {
      // Creates the config JSON
      this._config = { ...this.defaultConfig, ...this.config };

      const config = { ...this._config };
      config.modules = [EffectFade, Navigation, FreeMode, Pagination];

      this.instance = new Swiper(this.$refs.slider, config);
    }

  }
});
