import Vue from 'vue';

export default Vue.component('video-gallery-component', {
  name: "VideoGallery",

  inject: [
    '$store'
  ],

  props: {
    list: {
      type: Array,
      default: []
    },
  },

  data() {
    return {
      videoIndex: 0,
      state: 'stop'
    };
  },

  mounted() {
  },

  methods: {
    onChangeVideo(newIndex) {
      this.videoIndex = newIndex;
      this.state = 'play';
      console.log('onChangeVideo');

      this.$store.scrolledElement = this.$el;

    }
  }
});


